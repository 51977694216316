@charset "UTF-8";
/*------------------------------------------------------------
	Reset
------------------------------------------------------------*/
html, body, div, span, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
abbr, address, cite, code,
del, dfn, em, img, ins, kbd, q, samp,
small, strong, sub, sup, var,
b, i,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, dialog, figure, footer, header,
nav, section,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  box-sizing: border-box;
}

article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section, main {
  display: block;
}

html {
  font-size: 62.5%;
  box-sizing: border-box;
}

body {
  -webkit-text-size-adjust: 100%;
}

body, table,
input, textarea, select, option,
h1, h2, h3, h4, h5, h6 {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h1, h2, h3, h4, h5, h6 {
  font-weight: bold;
}

table,
input, textarea, select, option {
  line-height: 1.1;
}

ol, ul {
  list-style: none;
}

blockquote, q {
  quotes: none;
}

:focus {
  outline: 0;
}

ins {
  text-decoration: none;
}

del {
  text-decoration: line-through;
}

img {
  vertical-align: top;
}

a,
a img {
  transition: 0.3s ease-in-out;
}

a {
  color: inherit;
  text-decoration: none;
}

a:hover,
a:hover img {
  opacity: 0.80;
  filter: alpha(opacity=80);
  -ms-filter: "alpha(opacity=80)";
}

img {
  max-width: 100%;
}

.sp-only {
  display: none;
}

@media only screen and (max-width: 767px) {
  .pc-only {
    display: none;
  }
  .sp-only {
    display: block;
  }
}

/*≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡
// Layout .l-
≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡*/
/* Header
------------------------------------------------------------*/
.c-header {
  background-image: url("../img/main-header.jpg");
  height: 801.8px;
  background-position: center;
  background-repeat: no-repeat;
  padding-top: 41px;
  position: relative;
}

@media only screen and (max-width: 767px) {
  .c-header {
    padding-top: 32px;
  }
}

.c-header__logo {
  margin-left: 55px;
}

@media only screen and (max-width: 767px) {
  .c-header__logo {
    margin-left: 15px;
  }
}

@media only screen and (max-width: 516px) {
  .c-header__logo {
    width: 230px;
    margin-left: 10px;
    margin-top: 7px;
  }
}

.c-header__man {
  position: absolute;
  top: 245px;
  right: 0.4%;
}

@media only screen and (max-width: 767px) {
  .c-header__man {
    left: 0;
  }
}

.c-header__blur {
  height: 186.4px;
  position: absolute;
  bottom: 0;
  width: 100%;
}

.c-header__menu {
  width: 668px;
  position: absolute;
  color: white;
  font-size: 2.011rem;
  display: -webkit-box;
  display: flex;
  z-index: 2;
  bottom: -632px;
  right: 132px;
  justify-content: space-between;
}

@media only screen and (max-width: 1024px) {
  .c-header__menu {
    right: 50%;
    transform: translateX(50%);
  }
}

@media only screen and (max-width: 767px) {
  .c-header__menu {
    display: none;
  }
}

.c-header__active {
  padding: 0 2px 5px;
  border-bottom: 3px solid #e60013;
}

/* Main menu
------------------------------------------------------------*/
.c-header__text {
  font-size: 3.126rem;
  position: absolute;
  top: 179px;
  left: 50%;
  letter-spacing: 0px;
  opacity: 0;
  transform: translateX(-50%);
  visibility: hidden;
  -webkit-transition: 2s ease-in-out;
  transition: 2s ease-in-out;
  font-weight: bold;
  line-height: 1.7;
}

@media only screen and (max-width: 767px) {
  .c-header__text {
    top: 50%;
    transform: translate(-50%, -50%);
  }
}

@media only screen and (max-width: 516px) {
  .c-header__text {
    left: 75%;
  }
}

.c-header__text ul {
  height: 466px;
  display: -webkit-box;
  display: flex;
}

.c-header__text li:nth-child(1) {
  -webkit-transition-delay: 1.2s;
  transition-delay: 1.2s;
}

.c-header__text li:nth-child(2) {
  -webkit-transition-delay: 0.4s;
  transition-delay: 0.4s;
}

.c-header__text li {
  height: 0;
  overflow: hidden;
  margin-bottom: 5px;
  -webkit-transition: 2s ease-in-out;
  transition: 2s ease-in-out;
}

.c-header__text__show li {
  height: 100% !important;
}

.c-header__text li span {
  -webkit-writing-mode: vertical-rl;
  -ms-writing-mode: tb-rl;
  writing-mode: vertical-rl;
  -webkit-text-orientation: upright;
  text-orientation: upright;
  display: block;
  height: 466px;
}

.c-header__text li:first-child span {
  margin-top: 24px;
}

.c-header__text__show {
  opacity: 1;
  visibility: visible;
}

.p-up-to-top__container__is__menu {
  z-index: 999;
  position: fixed;
  width: 100%;
  top: 50%;
  transform: translateY(-50%);
}

.c-mainvisual__meta {
  z-index: 999;
  position: absolute;
  right: 0;
  top: 336px;
  width: 65px;
}

@media only screen and (max-width: 767px) {
  .c-mainvisual__meta {
    display: none;
  }
}

.c-mainvisual__orange {
  display: block;
  margin-bottom: 10px;
  color: white;
  width: 100%;
  height: 89px;
  background-color: #f5a124;
  text-align: center;
}

.c-mainvisual__green {
  display: block;
  margin-bottom: 10px;
  color: white;
  width: 100%;
  height: 92px;
  background-color: #5cb531;
  text-align: center;
}

.c-mainvisual__item {
  display: block;
  margin-bottom: 10px;
}

.c-mainvisual__orange img {
  margin-top: 25px;
  width: 30px;
}

.c-mainvisual__text {
  margin-top: 11px;
  display: inline-block;
  letter-spacing: 0px;
  font-size: 0.9rem;
  text-align: left;
}

.c-mainvisual__green img {
  margin-top: 12px;
  width: 30px;
}

.is_none {
  display: none;
}

.c-mainvisual__green.is_small img {
  margin-top: 7px;
}

.c-mainvisual__orange.is_small img {
  margin-top: 14px;
}

.is_menu {
  width: 44px;
  top: 50%;
  transform: translatey(-50%);
}

.is_small {
  height: 44px;
}

.c-header__meta {
  z-index: 1001;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  flex-direction: column;
  display: -webkit-box;
  display: flex;
  position: absolute;
  top: 20px;
  right: 2.6%;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
}

.p-burger {
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  margin-top: 14px;
}

.p-burger .p-burger__container {
  justify-content: space-around;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  flex-direction: column;
  height: 30px;
  height: 40px;
  position: relative;
  cursor: pointer;
}

.p-burger .p-burger__container {
  width: 44px;
  display: -webkit-box;
  display: flex;
}

.p-burger .c-line {
  width: 44px;
  height: 4px;
  background-color: #231916;
  -webkit-transition: all 0.2s;
  transition: all 0.2s;
}

.p-burger__text {
  font-weight: bolder;
  color: #231916;
  text-transform: uppercase;
  letter-spacing: 1px;
}

.p-burger.is-active .c-line {
  position: absolute;
}

.p-burger .c-line.is-active {
  background-color: white;
}

.p-burger.is-active .c-line:nth-child(1) {
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}

.p-burger.is-active .c-line:nth-child(2) {
  display: none;
}

.p-burger.is-active .c-line:nth-child(3) {
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
}

.p-burger__text.is-active {
  color: white;
}

.c-menu {
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 1000;
  background-color: #231916;
  top: 0;
  left: 0;
  -webkit-transform: translateY(-100%);
  transform: translateY(-100%);
  -webkit-transition: 0.4s -webkit-transform ease-in-out;
  transition: 0.4s -webkit-transform ease-in-out;
  transition: 0.4s transform ease-in-out;
  transition: 0.4s transform ease-in-out, 0.4s -webkit-transform ease-in-out;
}

.c-menu.is-open {
  -webkit-transform: translateY(0);
  transform: translateY(0);
}

.c-header .l-container1 {
  height: 578px;
  padding-top: 32px;
}

.c-menu__logo {
  z-index: 5;
  position: absolute;
  top: 32px;
  left: 20px;
}

@media only screen and (max-width: 516px) {
  .c-menu__logo {
    width: 230px;
    left: 10px;
  }
}

.c-menu ul {
  position: absolute;
  width: 70%;
  top: 30%;
  left: 43%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
}

.c-menu li {
  margin-bottom: 20px;
  font-size: 2rem;
  color: white;
}

body.is-hidden {
  overflow-y: hidden;
}

.c-header__meta {
  display: none;
}

@media only screen and (max-width: 767px) {
  .c-header__meta {
    display: block;
  }
}

@media only screen and (max-width: 516px) {
  .p-burger .p-burger__container {
    width: 35px;
  }
  .p-burger .c-line {
    width: 35px;
  }
  .p-burger__text {
    font-size: 1.1rem;
  }
}

/*------------------------------------------------------------
footer
------------------------------------------------------------*/
.c-footer {
  padding-top: 23px;
  position: relative;
  height: 232.2px;
  text-align: center;
  background-color: #231916;
  color: white;
}

@media only screen and (max-width: 767px) {
  .c-footer {
    padding-bottom: 330px;
  }
}

@media only screen and (max-width: 516px) {
  .c-footer {
    padding-bottom: 400px;
  }
}

.c-footer__menu {
  margin: 0 auto 31px;
  max-width: 863px;
  color: white;
  font-size: 2.011rem;
  display: -webkit-box;
  display: flex;
  z-index: 2;
  -webkit-box-pack: justify;
  justify-content: space-between;
  width: 100%;
  flex-wrap: wrap;
}

@media only screen and (max-width: 1024px) {
  .c-footer__menu {
    padding: 0 10px;
  }
}

@media only screen and (max-width: 767px) {
  .c-footer__menu {
    width: 95%;
  }
}

@media only screen and (max-width: 767px) {
  .c-footer__menu li {
    width: 26%;
    text-align: left;
  }
  .c-footer__menu li:last-child {
    width: 100%;
  }
}

@media only screen and (max-width: 516px) {
  .c-footer__menu li {
    width: 34%;
    margin-bottom: 10px;
    text-align: left;
  }
}

.c-footer__text {
  margin-top: 39px;
  display: block;
  font-size: 1.3rem;
}

.c-footer__social {
  position: fixed;
  bottom: 0;
  left: 0;
  display: none;
  height: 71px;
  -webkit-box-align: center;
  align-items: center;
  width: 100%;
}

@media only screen and (max-width: 767px) {
  .c-footer__social {
    display: -webkit-box;
    display: flex;
  }
}

.c-footer__social a {
  -webkit-box-flex: 1;
  flex: 1;
  display: block;
  height: 100%;
}

.c-footer__social a:nth-child(1) {
  background-color: #f5a124;
}

.c-footer__social a:nth-child(2) {
  background-color: #5cb531;
}

.c-footer__social a:nth-child(3) {
  background-color: #d578ac;
}

.c-footer__social a:nth-child(4) {
  background-color: white;
}

.c-footer__social a .c-image1 {
  width: 100%;
  height: 100%;
  display: -webkit-box;
  display: flex;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
}

.c-footer__text1 {
  font-size: 1.2rem;
}

@media only screen and (max-width: 516px) {
  .c-footer__text1 {
    display: none;
  }
}

/*------------------------------------------------------------
sidebar
------------------------------------------------------------*/
/* Breadcrumb
------------------------------------------------------------*/
/* layout
------------------------------------------------------------*/
body {
  font-family: "kozuka-mincho-pro",serif;
  font-size: 1.4rem;
  line-height: 1.5;
}

main {
  background-color: #231916;
  color: white;
}

.l-container {
  max-width: 1200px;
  margin: 0 auto;
  position: relative;
}

/*≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡
// Component .c-
≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡*/
/*------------------------------------------------------------
btn
------------------------------------------------------------*/
/*
------------------------------------------------------------*/
/*------------------------------------------------------------
title
------------------------------------------------------------*/
/*
------------------------------------------------------------*/
/*------------------------------------------------------------
icon
------------------------------------------------------------*/
/*
------------------------------------------------------------*/
/*------------------------------------------------------------
form
------------------------------------------------------------*/
/*
------------------------------------------------------------*/
/*------------------------------------------------------------
text
------------------------------------------------------------*/
/*
------------------------------------------------------------*/
/*------------------------------------------------------------
navi
------------------------------------------------------------*/
/*
------------------------------------------------------------*/
/*------------------------------------------------------------
img
------------------------------------------------------------*/
/*
------------------------------------------------------------*/
/*------------------------------------------------------------
list
------------------------------------------------------------*/
/*
------------------------------------------------------------*/
/*------------------------------------------------------------
table
------------------------------------------------------------*/
/*
------------------------------------------------------------*/
/*------------------------------------------------------------
line
------------------------------------------------------------*/
/*
------------------------------------------------------------*/
/*------------------------------------------------------------
video
------------------------------------------------------------*/
/*
------------------------------------------------------------*/
/*------------------------------------------------------------
video
------------------------------------------------------------*/
/*
------------------------------------------------------------*/
/*------------------------------------------------------------
other
------------------------------------------------------------*/
/*
------------------------------------------------------------*/
/*≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡
// Project .p-
≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡*/
/*------------------------------------------------------------
Css of home page
------------------------------------------------------------*/
.p-index1 {
  position: relative;
  width: 100%;
  clip-path: polygon(0% 0%, 100% 0, 100% 74%, 0 100%);
}

.p-index1 img {
  min-height: 1000px;
  width: 100%;
  object-fit: cover;
}

.p-index1__content {
  max-width: 1200px;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
  height: 100%;
  padding-top: 36.7%;
}

@media only screen and (max-width: 1024px) {
  .p-index1__content {
    padding-top: 438px;
  }
}

.p-index1__meta {
  margin-left: 104px;
}

@media only screen and (max-width: 516px) {
  .p-index1__meta {
    text-align: center;
    margin-left: 0;
  }
}

.p-index1__title {
  font-style: italic;
  margin-left: 77px;
  font-size: 3.944rem;
  color: #e60013;
  font-weight: 600;
}

@media only screen and (max-width: 516px) {
  .p-index1__title {
    margin-left: 0;
  }
}

.p-index1__youtube {
  background-color: white;
  width: 352.3px;
  height: 221.3px;
}

@media only screen and (max-width: 516px) {
  .p-index1__youtube {
    margin: 0 auto;
    width: 290px;
  }
}

.p-index2 {
  max-height: 1200px;
  margin-top: -39%;
  width: 100%;
  clip-path: polygon(0% 26%, 100% 0%, 100% 100%, 0% 120%);
  position: relative;
}

@media only screen and (max-width: 767px) {
  .p-index2 {
    margin-top: -262px;
    max-height: 1300px;
    clip-path: polygon(0% 20%, 100% 0%, 100% 100%, 0% 120%);
  }
}

.p-index2__bg {
  min-height: 1000px;
  width: 100%;
}

@media only screen and (max-width: 767px) {
  .p-index2__bg {
    min-height: 1350px;
    display: none;
  }
}

.p-index2__bg.sp {
  display: none;
}

@media only screen and (max-width: 767px) {
  .p-index2__bg.sp {
    display: block;
  }
}

.p-index2__content {
  max-width: 1200px;
  position: absolute;
  top: 0;
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  width: 100%;
  height: 100%;
  padding-top: 19%;
}

@media only screen and (max-width: 1024px) {
  .p-index2__content {
    padding-top: 226px;
  }
}

.p-index2__meta {
  position: relative;
}

.p-index2__meta h2 {
  text-align: center;
  font-size: 4.887rem;
  color: #FFF;
}

.p-index2__text {
  margin-top: 30px;
  font-size: 1.8rem;
  display: -webkit-box;
  display: flex;
  -webkit-box-pack: center;
  justify-content: center;
  line-height: 1.8;
}

@media only screen and (max-width: 516px) {
  .p-index2__text {
    padding: 0 13px;
    font-size: 1.4rem;
  }
}

.p-index2__list {
  margin: 82px auto;
  max-width: 1026px;
  display: -webkit-box;
  display: flex;
  -webkit-box-pack: justify;
  justify-content: space-between;
  flex-wrap: wrap;
  padding: 0 20px;
}

@media only screen and (max-width: 516px) {
  .p-index2__list {
    padding: 0 15px;
  }
}

.p-index2__item {
  width: 47.5%;
}

@media only screen and (max-width: 767px) {
  .p-index2__item {
    margin: 0 auto;
    width: 477px;
    margin-bottom: 30px;
  }
}

.p-index2__info {
  font-size: 2.2rem;
}

.p-index2__detail {
  display: inline-block;
  margin-top: 14px;
  font-size: 1.3rem;
  line-height: 1.7;
}

.p-index2__blur {
  position: absolute;
  width: 386.6px;
  right: 30px;
  top: -46px;
}

@media only screen and (max-width: 1024px) {
  .p-index2__blur {
    width: 244px;
  }
}

@media only screen and (max-width: 767px) {
  .p-index2__blur {
    width: 155px;
  }
}

@media only screen and (max-width: 516px) {
  .p-index2__blur {
    width: 130px;
    right: 5px;
    top: -109px;
  }
}

.p-index3 {
  width: 100%;
  position: relative;
  margin-top: -125px;
}

.p-index3 h2 {
  text-align: center;
  font-size: 4.887rem;
  color: #FFF;
}

.p-index3__bg {
  height: 730px;
  width: 100%;
}

@media only screen and (max-width: 767px) {
  .p-index3__bg {
    height: 1000px;
  }
}

.p-index3__content {
  max-width: 1200px;
  position: absolute;
  top: 0;
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  width: 100%;
  height: 100%;
  padding-top: 134px;
}

@media only screen and (max-width: 767px) {
  .p-index3__content {
    padding-top: 155px;
  }
}

.p-index3__list {
  margin: 82px auto;
  max-width: 951px;
  display: -webkit-box;
  display: flex;
  -webkit-box-pack: justify;
  justify-content: space-between;
  flex-wrap: wrap;
}

@media only screen and (max-width: 1024px) {
  .p-index3__list {
    padding: 0 20px;
  }
}

@media only screen and (max-width: 767px) {
  .p-index3__list {
    margin: 55px auto;
  }
}

.p-index3__image img {
  width: 187px;
}

.p-index3__item {
  width: 20%;
}

@media only screen and (max-width: 1024px) {
  .p-index3__item {
    width: 22%;
  }
}

@media only screen and (max-width: 767px) {
  .p-index3__item {
    display: flex;
    width: 48%;
    align-items: center;
    flex-direction: column;
    margin-bottom: 20px;
  }
}

.p-index3__date {
  margin-bottom: 5px;
  display: block;
  margin-top: 9px;
  font-size: 1.6rem;
}

.p-index3__info {
  display: block;
  font-size: 1.9rem;
}

.p-index4 iframe {
  clip-path: polygon(0% 21%, 100% 0%, 100% 100%, 0% 120%);
  margin-top: -111px;
}

@media only screen and (max-width: 767px) {
  .p-index4 iframe {
    clip-path: polygon(0% 28%, 100% 0%, 100% 100%, 0% 120%);
    margin-top: -153px;
  }
}

.c-title {
  text-align: center;
  font-size: 2.8rem;
}

.p-topic {
  padding-top: 61px;
}

.p-topic__date {
  margin-bottom: 5px;
  display: block;
  margin-top: 9px;
  font-size: 1.6rem;
}

.p-topic__info {
  display: block;
  font-size: 1.9rem;
}

.p-topic__list {
  margin: 73px auto 0;
  max-width: 975px;
  display: -webkit-box;
  display: flex;
  -webkit-box-pack: justify;
  justify-content: space-between;
  flex-wrap: wrap;
}

@media only screen and (max-width: 1024px) {
  .p-topic__list {
    padding: 0 20px;
  }
}

@media only screen and (max-width: 767px) {
  .p-topic__list {
    margin: 55px auto 0;
  }
}

.p-topic__image img {
  width: 187px;
}

.p-topic__item {
  width: 22%;
  margin-bottom: 80px;
}

@media only screen and (max-width: 1024px) {
  .p-topic__item {
    width: 22%;
  }
}

@media only screen and (max-width: 767px) {
  .p-topic__item {
    display: flex;
    width: 48%;
    align-items: center;
    flex-direction: column;
  }
}

.p-service {
  padding-top: 40px;
  padding-bottom: 337px;
}

.p-service__info {
  margin-top: 75px;
  font-size: 2.354rem;
  margin-left: 114px;
  line-height: 1.7;
}

.p-service__list {
  margin: 25px auto 0;
  max-width: 975px;
  display: -webkit-box;
  display: flex;
  -webkit-box-pack: justify;
  justify-content: space-between;
  flex-wrap: wrap;
  font-size: 1.367rem;
}

.p-service__item {
  width: 46%;
}

.p-service__item:first-child {
  margin-top: -4px;
  line-height: 1.8;
}

.p-service__item:last-child {
  margin-right: 31px;
}

.p-service__meta {
  margin: 48px auto 0;
  max-width: 952px;
  display: -webkit-box;
  display: flex;
  -webkit-box-pack: justify;
  justify-content: space-between;
  flex-wrap: wrap;
  font-size: 1.367rem;
}

.p-service__video {
  margin-bottom: 43px;
}

.p-service__video video {
  object-fit: cover;
}

.p-privacy {
  padding-top: 50px;
}

.p-policy__info {
  margin-bottom: 53px;
}

.p-policy__meta {
  max-width: 778px;
  margin: 70px auto 0;
}

.p-policy__content h3 {
  font-size: 1.8rem;
  margin-top: 26px;
}

.p-policy__content p {
  line-height: 1.7;
  margin-top: 6px;
}

.p-policy__content a {
  font-size: 1.359rem;
  border: 1px solid white;
  display: inline-block;
  padding: 13px 29px;
  border-radius: 50px;
  margin-top: 62px;
  margin-bottom: 90px;
}

.p-policy__content a:hover {
  color: #231916;
  background-color: white;
}

.p-message {
  padding-top: 34px;
  padding-bottom: 381px;
}

.p-message__list {
  margin: 56px auto 0;
  max-width: 1041px;
  display: -webkit-box;
  display: flex;
  -webkit-box-pack: justify;
  justify-content: space-between;
  flex-wrap: wrap;
  font-size: 1.367rem;
}

.p-message__content {
  font-size: 1.5rem;
  width: 53%;
  margin-right: 105px;
  margin-top: 14px;
  line-height: 2.3;
}

.p-message__sign {
  font-size: 2.067rem;
  margin-top: 12px;
}

.p-detail {
  padding-bottom: 381px;
  padding-top: 51px;
}

.p-detail__date {
  font-size: 2rem;
  margin-bottom: 7px;
}

.p-detail__info {
  font-size: 3rem;
  margin-bottom: 31px;
}

.p-detail__meta {
  margin: 66px auto 0;
  max-width: 771px;
}

.p-detail__content {
  line-height: 1.8;
}

.p-detail__content img {
  margin-top: 10px;
}

/*------------------------------------------------------------
Css of home page
------------------------------------------------------------*/
.p-company__list {
  display: -webkit-box;
  display: flex;
  width: 100%;
  max-width: 900px;
  -webkit-box-pack: justify;
  justify-content: space-between;
  flex-wrap: wrap;
  margin: 0 auto;
  margin-top: 59px;
}

@media only screen and (max-width: 767px) {
  .p-company__list {
    padding: 0 20px;
  }
}

.p-company__item {
  width: 32.2%;
  margin-bottom: 16px;
}

@media only screen and (max-width: 767px) {
  .p-company__item {
    width: 31.3%;
  }
}

.p-company__meta {
  letter-spacing: 1px;
  margin: 0 auto;
  width: 90%;
  max-width: 823px;
  margin-top: 41px;
}

@media only screen and (max-width: 516px) {
  .p-company__meta {
    line-height: 2.5;
  }
}

.p-company__info {
  display: flex;
  padding: 33px 0px 28px;
  border-bottom: 1px solid white;
}

.p-company__info span:first-child {
  margin-left: 95px;
  width: 20.5%;
}

.p-company__info span:nth-child(2) {
  letter-spacing: 0.3px;
}

@media only screen and (max-width: 767px) {
  .p-company__info span:first-child {
    margin-left: 24px;
  }
}

@media only screen and (max-width: 516px) {
  .p-company__info span:first-child {
    margin-left: 10px;
    width: 33%;
  }
  .p-company__info span:nth-child(2) {
    width: 60%;
  }
}

.p-lightbox__content {
  position: relative;
  width: 100%;
  height: 100%;
}

.p-lightbox__box, .p-lightbox__box__2 {
  display: none;
  position: absolute;
  z-index: 2;
  width: 100%;
  height: 100%;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
  overflow: hidden;
}

.p-lightbox .c-number {
  color: #f2f2f2;
  font-size: 12px;
  padding: 8px 12px;
  position: absolute;
  top: 0;
}

.p-lightbox__box img, .p-lightbox__box__2 img {
  max-width: 90vw;
  height: 80vh;
  cursor: pointer;
  position: absolute;
  z-index: 2;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

@media only screen and (max-width: 1024px) {
  .p-lightbox__box img, .p-lightbox__box__2 img {
    width: 85%;
    height: auto;
    max-height: 80vh;
  }
}

.p-lightbox .c-hover__shadow {
  -webkit-transition: 0.3s;
  transition: 0.3s;
}

.p-lightbox__outside {
  position: absolute;
  z-index: 1;
  width: 100%;
  height: 100%;
  cursor: pointer;
}

.p-lightbox {
  display: none;
  position: fixed;
  z-index: 1000;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.8);
}

.p-lightbox .c-close {
  color: white;
  position: absolute;
  top: 10px;
  right: 25px;
  font-size: 35px;
  font-weight: bold;
  z-index: 999;
}

.p-lightbox .c-prev {
  left: 20px;
  border-radius: 3px 0 0 3px;
}

.p-lightbox .c-next {
  right: 20px;
  border-radius: 3px 0 0 3px;
}

.p-lightbox .c-prev, .p-lightbox .c-next {
  cursor: pointer;
  position: absolute;
  top: 50%;
  width: auto;
  padding: 16px;
  margin-top: -50px;
  color: white;
  font-weight: bold;
  font-size: 20px;
  -webkit-transition: 0.6s ease;
  transition: 0.6s ease;
  border-radius: 0 3px 3px 0;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-user-select: none;
  z-index: 999;
}

@media only screen and (max-width: 767px) {
  .p-lightbox .c-next {
    right: -5px;
  }
  .p-lightbox .c-prev {
    left: -5px;
  }
}

/*≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡
// Utility .u-
≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡*/
