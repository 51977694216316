/*------------------------------------------------------------
footer
------------------------------------------------------------*/
.c-footer{
   padding-top: 23px;
    position: relative;
    height: 232.2px;
    text-align: center;
    background-color: #231916;
    color: white;
    @media only screen and (max-width: 767px) {
    padding-bottom: 330px;
    }
    @media only screen and (max-width: 516px) {
      padding-bottom: 400px;
    }
 &__menu{
   margin: 0 auto 31px;
    max-width: 863px;
    color: white;
    font-size: 2.011rem;
    display: -webkit-box;
    display: flex;
    z-index: 2;
    -webkit-box-pack: justify;
    justify-content: space-between;
    width: 100%;
    flex-wrap: wrap;
    @media only screen and (max-width: 1024px) {
       padding: 0 10px;
    }
    @media only screen and (max-width: 767px) {
       width: 95%;
    }
    li{
       @media only screen and (max-width: 767px) {
          width: 26%;
          text-align: left;
          &:last-child{
             width: 100%;
          }
       }
       @media only screen and (max-width: 516px) {
         width: 34%;
         margin-bottom: 10px;
         text-align: left;
       }
    }
 }
 &__text{
    margin-top: 39px;
    display: block;
    font-size: 1.3rem;
 }
}
.c-footer__social {
   position: fixed;
   bottom: 0;
   left: 0;
   display: none;
   height: 71px;
   -webkit-box-align: center;
   align-items: center;
   width: 100%;
}
@media only screen and (max-width: 767px)
{
   .c-footer__social {
      display: -webkit-box;
      display: flex;
  }
}
.c-footer__social a {
   -webkit-box-flex: 1;
   flex: 1;
   display: block;
   height: 100%;
}
.c-footer__social a:nth-child(1) {
   background-color: #f5a124;
}
.c-footer__social a:nth-child(2) {
   background-color: #5cb531;
}
.c-footer__social a:nth-child(3) {
   background-color: #d578ac;
}
.c-footer__social a:nth-child(4) {
   background-color: white;
}
.c-footer__social a .c-image1 {
   width: 100%;
   height: 100%;
   display: -webkit-box;
   display: flex;
   -webkit-box-pack: center;
   justify-content: center;
   -webkit-box-align: center;
   align-items: center;
}
.c-footer__text1{
   font-size: 1.2rem;
   @media only screen and (max-width: 516px) {
      display: none;
   }
}
