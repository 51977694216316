/* layout
------------------------------------------------------------*/
body{
	font-family:"kozuka-mincho-pro",serif;
	font-size: 1.4rem;
	line-height: 1.5;
}
//-------------------------------------------------------
main{
	background-color: #231916;
color: white;
}

section{
	
}

//-------------------------------------------------------
.l-container{
	max-width: 1200px;
	margin: 0 auto;
	position: relative;
}
//-------------------------------------------------------
.c-mainvisual{

}

