/* Header
------------------------------------------------------------*/
.c-header{
   background-image: url('../img/main-header.jpg');
   height: 801.8px;
   background-position: center;
   background-repeat: no-repeat;
   padding-top: 41px;
   position: relative;
@media only screen and (max-width: 767px) {
   padding-top: 32px;
}
 
   &__logo{
      margin-left: 55px;
      @media only screen and (max-width: 767px) {
         margin-left: 15px;
      }
      @media only screen and (max-width: 516px) {
         width: 230px;
         margin-left: 10px;
         margin-top: 7px;
      }
   }
   &__man{
      position: absolute;
      top: 245px;
      right: 0.4%;
      @media only screen and (max-width: 767px) {
         left: 0;
      }
   }
   &__blur{
      height: 186.4px;
      position: absolute;
      bottom: 0;
      width: 100%;
   }
   &__menu{
      width: 668px;
      position: absolute;
      color: white;
      font-size: 2.011rem;
      display: -webkit-box;
      display: flex;
      z-index: 2;
      bottom: -632px;
      right: 132px;
      justify-content: space-between;
      @media only screen and (max-width: 1024px) {
         right: 50%;
         transform: translateX(50%);
      }
      @media only screen and (max-width: 767px) {
         display: none;
      }
   }
   &__active{
      padding: 0 2px 5px;
      border-bottom: 3px solid #e60013;
   }
}
/* Main menu
------------------------------------------------------------*/
.c-gnav{

}

.c-header__text {
   font-size: 3.126rem;
   position: absolute;
   top: 179px;
   left: 50%;
   letter-spacing: 0px;
   opacity: 0;
   transform: translateX(-50%);
   visibility: hidden;
   -webkit-transition: 2s ease-in-out;
   transition: 2s ease-in-out;
   font-weight: bold;
   line-height: 1.7;
   @media only screen and (max-width: 767px) {
      top: 50%;
      transform: translate(-50%,-50%);
   }
   @media only screen and (max-width: 516px) {
      left: 75%;
   }
}
.c-header__text ul {
   height: 466px;
   display: -webkit-box;
   display: flex;
}
.c-header__text li:nth-child(1) {
   -webkit-transition-delay: 1.2s;
   transition-delay: 1.2s;
}
.c-header__text li:nth-child(2) {
   -webkit-transition-delay: 0.4s;
   transition-delay: 0.4s;
}
.c-header__text li {
   height: 0;
   overflow: hidden;
   margin-bottom: 5px;
   -webkit-transition: 2s ease-in-out;
   transition: 2s ease-in-out;

}
.c-header__text__show li {
   height: 100% !important;
}
.c-header__text li span {
   -webkit-writing-mode: vertical-rl;
   -ms-writing-mode: tb-rl;
   writing-mode: vertical-rl;
   -webkit-text-orientation: upright;
   text-orientation: upright;
   display: block;
   height: 466px;
}
.c-header__text li:first-child span {
   margin-top: 24px;
}
.c-header__text__show {
   opacity: 1;
   visibility: visible;
}

.p-up-to-top__container__is__menu {
   z-index: 999;
   position: fixed;
   width: 100%;
   top: 50%;
   transform: translateY(-50%);
}

.c-mainvisual__meta {
   z-index: 999;
   position: absolute;
   right: 0;
   top: 336px;
   width: 65px;
   @media only screen and (max-width: 767px) {
      display: none;
   }
}

.c-mainvisual__orange {
   display: block;
   margin-bottom: 10px;
   color: white;
   width: 100%;
   height: 89px;
   background-color: #f5a124;
   text-align: center;
}
.c-mainvisual__green {
   display: block;
   margin-bottom: 10px;
   color: white;
   width: 100%;
   height: 92px;
   background-color: #5cb531;
   text-align: center;
}
.c-mainvisual__item {
   display: block;
   margin-bottom: 10px;
}
.c-mainvisual__orange img {
   margin-top: 25px;
   width: 30px;
}
.c-mainvisual__text {
   margin-top: 11px;
   display: inline-block;
   letter-spacing: 0px;
   font-size: 0.9rem;
   text-align: left;
}
.c-mainvisual__green img {
   margin-top: 12px;
   width: 30px;
}
.is_none {
   display: none;
}
.c-mainvisual__green.is_small img {
   margin-top: 7px;
}
.c-mainvisual__orange.is_small img {
   margin-top: 14px;
}
.is_menu {
   width: 44px;
   top: 50%;
   transform: translatey(-50%);
}
.is_small {
   height: 44px;
}

.c-header__meta {
   z-index: 1001;
   -webkit-box-orient: vertical;
   -webkit-box-direction: normal;
   flex-direction: column;
   display: -webkit-box;
   display: flex;
   position: absolute;
   top: 20px;
   right: 2.6%;
   -webkit-box-pack: center;
   justify-content: center;
   -webkit-box-align: center;
   align-items: center;
}
.p-burger {
   -webkit-transition: all 0.3s;
   transition: all 0.3s;
   margin-top: 14px;
}
.p-burger .p-burger__container {
   justify-content: space-around;
   -webkit-box-orient: vertical;
   -webkit-box-direction: normal;
   flex-direction: column;
   height: 30px;
   height: 40px;
   position: relative;
   cursor: pointer;
}
.p-burger .p-burger__container {
   width: 44px;
   display: -webkit-box;
   display: flex;
}
.p-burger .c-line {
   width: 44px;
   height: 4px;
   background-color: #231916;
   -webkit-transition: all 0.2s;
   transition: all 0.2s;
}
.p-burger__text {
   font-weight: bolder;
   color: #231916;
   text-transform: uppercase;
   letter-spacing: 1px;
}
.p-burger.is-active .c-line {
   position: absolute;
}
.p-burger .c-line.is-active {
   background-color: white;
}
.p-burger.is-active .c-line:nth-child(1) {
   -webkit-transform: rotate(
45deg
);
   transform: rotate(
45deg
);
}
.p-burger.is-active .c-line:nth-child(2) {
   display: none;
}
.p-burger.is-active .c-line:nth-child(3) {
   -webkit-transform: rotate(
-45deg
);
   transform: rotate(
-45deg
);
}
.p-burger__text.is-active {
   color: white;
}
.c-menu {
   position: fixed;
   width: 100%;
   height: 100%;
   z-index: 1000;
   background-color: #231916;
   top: 0;
   left: 0;
   -webkit-transform: translateY(-100%);
   transform: translateY(-100%);
   -webkit-transition: 0.4s -webkit-transform ease-in-out;
   transition: 0.4s -webkit-transform ease-in-out;
   transition: 0.4s transform ease-in-out;
   transition: 0.4s transform ease-in-out, 0.4s -webkit-transform ease-in-out;
}
.c-menu.is-open {
   -webkit-transform: translateY(0);
   transform: translateY(0);
}
.c-header .l-container1 {
   height: 578px;
   padding-top: 32px;
}
.c-menu__logo {
   z-index: 5;
   position: absolute;
   top: 32px;
   left: 20px;
   @media only screen and (max-width: 516px) {
      width: 230px;
      left: 10px;
   }
}
.c-menu ul {
   position: absolute;
   width: 70%;
   top: 30%;
   left: 43%;
   -webkit-transform: translateX(-50%);
   transform: translateX(-50%);
}
.c-menu li {
   margin-bottom: 20px;
   font-size: 2rem;
   color: white;
}
body.is-hidden {
   overflow-y: hidden;
}
.c-header__meta{
   display: none;
}
@media only screen and (max-width: 767px) {
   .c-header__meta{
      display: block;
   }
}
@media only screen and (max-width: 516px)
{
   .p-burger .p-burger__container {
      width: 35px;
  }
  .p-burger .c-line {
   width: 35px;
}
.p-burger__text {
   font-size: 1.1rem;
}

}
