/*------------------------------------------------------------
Css of home page
------------------------------------------------------------*/
.p-company{
}
  .p-company__list {
    display: -webkit-box;
    display: flex;
    width: 100%;
    max-width: 900px;
    -webkit-box-pack: justify;
    justify-content: space-between;
    flex-wrap: wrap;
    margin: 0 auto;
    margin-top: 59px;
  }
  
  @media only screen and (max-width: 767px) {
    .p-company__list {
      padding: 0 20px;
    }
  }
  .p-company__item {
    width: 32.2%;
    margin-bottom: 16px;
  }
  @media only screen and (max-width: 767px) {
    .p-company__item {
      width: 31.3%;
    }
  }
  .p-company__meta {
    letter-spacing: 1px;
    margin: 0 auto;
    width: 90%;
    max-width: 823px;
    margin-top: 41px;
  }
  @media only screen and (max-width: 516px) {
    .p-company__meta {
      line-height: 2.5;
    }
  }
  .p-company__info {
    display: flex;
    padding: 33px 0px 28px;
    border-bottom: 1px solid white;
  }
  .p-company__info span:first-child {
    margin-left: 95px;
    width: 20.5%;
  }
  .p-company__info span:nth-child(2) {
    letter-spacing: 0.3px;
  }
  @media only screen and (max-width: 767px) {
    .p-company__info span:first-child {
      margin-left: 24px;
    }
  }
  @media only screen and (max-width: 516px) {
    .p-company__info span:first-child {
      margin-left: 10px;
      width: 33%;
    }
    .p-company__info span:nth-child(2) {
      width: 60%;
    }
  }
  .p-lightbox__content {
    position: relative;
    width: 100%;
    height: 100%;
  }
  .p-lightbox__box, .p-lightbox__box__2 {
    display: none;
    position: absolute;
    z-index: 2;
    width: 100%;
    height: 100%;
    -webkit-box-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    align-items: center;
    overflow: hidden;
  }
  .p-lightbox .c-number {
    color: #f2f2f2;
    font-size: 12px;
    padding: 8px 12px;
    position: absolute;
    top: 0;
  }
  .p-lightbox__box img, .p-lightbox__box__2 img {
    max-width: 90vw;
    height: 80vh;
    cursor: pointer;
    position: absolute;
    z-index: 2;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
  }
  @media only screen and (max-width: 1024px){
    .p-lightbox__box img, .p-lightbox__box__2 img {
      width: 85%;
      height: auto;
      max-height: 80vh;
  }
  }
  .p-lightbox .c-hover__shadow {
    -webkit-transition: 0.3s;
    transition: 0.3s;
  }
  .p-lightbox__outside {
    position: absolute;
    z-index: 1;
    width: 100%;
    height: 100%;
    cursor: pointer;
  }
  .p-lightbox {
    display: none;
    position: fixed;
    z-index: 1000;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: rgba(0, 0, 0, 0.8);
  }
  .p-lightbox .c-close {
    color: white;
    position: absolute;
    top: 10px;
    right: 25px;
    font-size: 35px;
    font-weight: bold;
    z-index: 999;
  }
  .p-lightbox .c-prev {
    left: 20px;
    border-radius: 3px 0 0 3px;
  }
  .p-lightbox .c-next {
    right: 20px;
    border-radius: 3px 0 0 3px;
  }
  .p-lightbox .c-prev, .p-lightbox .c-next {
    cursor: pointer;
    position: absolute;
    top: 50%;
    width: auto;
    padding: 16px;
    margin-top: -50px;
    color: white;
    font-weight: bold;
    font-size: 20px;
    -webkit-transition: 0.6s ease;
    transition: 0.6s ease;
    border-radius: 0 3px 3px 0;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -webkit-user-select: none;
    z-index: 999;
}
@media only screen and (max-width: 767px)
{
    .p-lightbox .c-next {
        right: -5px;
    }
    .p-lightbox .c-prev {
        left: -5px;
    }
     
}
