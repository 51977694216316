/*------------------------------------------------------------
Css of home page
------------------------------------------------------------*/
.p-index1{
	position: relative;
    width: 100%;
    clip-path: polygon(0% 0%, 100% 0, 100% 74%, 0 100%);
	
	// height: 1200px;
	
	// background-image: url(../img/bg-main1.jpg);
	// background-position: center;
	// background-repeat: no-repeat;
	// background-size: cover;
	// &__bg{
	// 	display: none;
	//  }
    img{
		min-height: 1000px;
		width: 100%;
		object-fit: cover;
    }
	&__content{
		max-width: 1200px;
		position: absolute;
		top: 0;
		left: 50%;
		transform: translateX(-50%);
		width: 100%;
		height: 100%;
		padding-top: 36.7%;
		@media only screen and (max-width: 1024px) {
			padding-top: 438px;
		}
	}
	&__meta{
		margin-left: 104px;
		@media only screen and (max-width: 516px) {
			text-align: center;
			margin-left: 0;
		}
	}
	&__title{
		font-style: italic;
    margin-left: 77px;
    font-size: 3.944rem;
    color: #e60013;
    font-weight: 600;
	@media only screen and (max-width: 516px) {
		margin-left: 0;
	}
	}
	&__youtube{
		background-color:white;
		width: 352.3px;
		height: 221.3px;
		@media only screen and (max-width: 516px) {
			margin: 0 auto;
			width: 290px;
		}
	}
}
.p-index2{
	max-height: 1200px;
	margin-top: -39%;
    width: 100%;
	clip-path: polygon(0% 26%, 100% 0%, 100% 100%, 0% 120%);
    position: relative;
	@media only screen and (max-width: 767px) {
		margin-top: -262px;
		max-height: 1300px;
		clip-path: polygon(0% 20%, 100% 0%, 100% 100%, 0% 120%);
	}

    &__bg{
		min-height: 1000px;
		width: 100%;
		@media only screen and (max-width: 767px) {
			min-height: 1350px;
			display: none;
		}
		&.sp{
			display: none;
			@media only screen and (max-width: 767px) {
				display: block;
			}
		}
    }
	&__content{
	    max-width: 1200px;
    position: absolute;
    top: 0;
    left: 50%;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
    width: 100%;
    height: 100%;
	padding-top: 19%;
	@media only screen and (max-width: 1024px) {
		padding-top: 226px;
	}
	}
	&__meta{
		position: relative;
        h2{
            text-align: center;
            font-size: 4.887rem;
            color: #FFF;
        }
	}
	&__text{
	    margin-top: 30px;
    font-size: 1.8rem;
    display: -webkit-box;
    display: flex;
    -webkit-box-pack: center;
    justify-content: center;
    line-height: 1.8;
	@media only screen and (max-width: 516px) {
	    padding: 0 13px;
    font-size: 1.4rem;
	}
	}
	&__list{
		margin: 82px auto;
		max-width: 1026px;
		display: -webkit-box;
		display: flex;
		-webkit-box-pack: justify;
		justify-content: space-between;
		flex-wrap: wrap;
		padding: 0 20px;
		@media only screen and (max-width: 516px) {
			padding: 0 15px;
		}
	}
	&__item{
		width: 47.5%;
		@media only screen and (max-width: 767px) {
			margin: 0 auto;
			width: 477px;
			margin-bottom: 30px;
		}
	}
	&__info{
		font-size: 2.2rem;
	}
	&__detail{
		display: inline-block;
    margin-top: 14px;
    font-size: 1.3rem;
    line-height: 1.7;
	}
	&__blur{
		position: absolute;
		width: 386.6px;
		right: 30px;
		top: -46px;
		@media only screen and (max-width: 1024px) {
			width: 244px;
		}
		@media only screen and (max-width: 767px) {
			width: 155px;
		}
		@media only screen and (max-width: 516px) {
			width: 130px;
			right: 5px;
			top: -109px;
		}
	}
}
.p-index3{
	h2{
		text-align: center;
		font-size: 4.887rem;
		color: #FFF;
	}
	width: 100%;
    position: relative;
    margin-top: -125px;
	&__bg{
		height: 730px;
		width: 100%;
		@media only screen and (max-width: 767px) {
			height: 1000px;
		}
	}
	&__content{
	    max-width: 1200px;
    position: absolute;
    top: 0;
    left: 50%;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
    width: 100%;
    height: 100%;
	padding-top: 134px;
	@media only screen and (max-width: 767px) {
		padding-top: 155px;
	}
	}
	&__list{
		margin: 82px auto;
		max-width: 951px;
		display: -webkit-box;
		display: flex;
		-webkit-box-pack: justify;
		justify-content: space-between;
		flex-wrap: wrap;
		@media only screen and (max-width: 1024px) {
			padding: 0 20px;
		}
		@media only screen and (max-width: 767px) {
			margin: 55px auto;
		}
	}
	&__image{
		img{
			width: 187px;
		}
	}
	&__item{
		width: 20%;
		@media only screen and (max-width: 1024px) {
			width: 22%;
		}
		@media only screen and (max-width: 767px) {
			display: flex;
			width: 48%;
			align-items: center;
			flex-direction: column;
			margin-bottom: 20px;
		}
	}
	&__date{
		margin-bottom: 5px;
    display: block;
    margin-top: 9px;
    font-size: 1.6rem;
	}
	&__info{
		display: block;
		font-size: 1.9rem;
	}
}
.p-index4{
	iframe{
	    clip-path: polygon(0% 21%, 100% 0%, 100% 100%, 0% 120%);
    margin-top: -111px;
	@media only screen and (max-width: 767px) {
		clip-path: polygon(0% 28%, 100% 0%, 100% 100%, 0% 120%);
    margin-top: -153px;
	}
	}
}
.c-title{
	text-align: center;
	font-size: 2.8rem;
}
.p-topic{
    padding-top: 61px;
	&__date{
		margin-bottom: 5px;
    display: block;
    margin-top: 9px;
    font-size: 1.6rem;
	}
	&__info{
		display: block;
		font-size: 1.9rem;
	}
	&__list{
		margin: 73px auto 0;
		max-width: 975px;
		display: -webkit-box;
		display: flex;
		-webkit-box-pack: justify;
		justify-content: space-between;
		flex-wrap: wrap;
		@media only screen and (max-width: 1024px) {
			padding: 0 20px;
		}
		
		@media only screen and (max-width: 767px) {
			margin: 55px auto 0;
		}
	}
	&__image{
		img{
			width: 187px;
		}
	}
	&__item{
		width: 22%;
		margin-bottom: 80px;
		@media only screen and (max-width: 1024px) {
			width: 22%;
		}
		@media only screen and (max-width: 767px) {
			display: flex;
			width: 48%;
			align-items: center;
			flex-direction: column;
		}
	}
}
.p-service{
	padding-top: 40px;
	padding-bottom: 337px;
	&__info{
		margin-top: 75px;
		font-size: 2.354rem;
		margin-left: 114px;
		line-height: 1.7;
	}
	
	&__list{
		margin: 25px auto 0;
		max-width: 975px;
		display: -webkit-box;
		display: flex;
		-webkit-box-pack: justify;
		justify-content: space-between;
		flex-wrap: wrap;
		font-size: 1.367rem;
	}
	&__item{
		width: 46%;
		&:first-child{
			margin-top: -4px;
			line-height: 1.8;
		}
		&:last-child{
			margin-right: 31px;
		}
	}
	&__meta{
		margin: 48px auto 0;
		max-width: 952px;
		display: -webkit-box;
		display: flex;
		-webkit-box-pack: justify;
		justify-content: space-between;
		flex-wrap: wrap;
		font-size: 1.367rem;
	}
&__video{
	margin-bottom: 43px;
	video{
		object-fit: cover;
	}
}

}
.p-privacy{
	padding-top: 50px;

}
.p-policy__info{
	margin-bottom: 53px;
}
.p-policy__meta {
	max-width: 778px;
	margin: 70px auto 0;
}
.p-policy__content h3 {
	font-size: 1.8rem;
	margin-top: 26px;
}
.p-policy__content p {
    line-height: 1.7;
	margin-top: 6px;
}

.p-policy__content a {
    font-size: 1.359rem;
    border: 1px solid white;
    display: inline-block;
    padding: 13px 29px;
    border-radius: 50px;
    margin-top: 62px;
    margin-bottom: 90px;
}
.p-policy__content a:hover {
    color: #231916;
    background-color: white;
}
.p-message{
	padding-top: 34px;
	padding-bottom: 381px;

	&__list{
		margin: 56px auto 0;
		max-width: 1041px;
		display: -webkit-box;
		display: flex;
		-webkit-box-pack: justify;
		justify-content: space-between;
		flex-wrap: wrap;
		font-size: 1.367rem;
	}

&__content{
	font-size: 1.5rem;
    width: 53%;
    margin-right: 105px;
    margin-top: 14px;
    line-height: 2.3;
}
&__sign{
	font-size: 2.067rem;
    margin-top: 12px;
}
}
.p-detail{
	padding-bottom: 381px;
	padding-top: 51px;
	&__date{
		font-size: 2rem;
		margin-bottom: 7px;
	}
	&__info{
		font-size: 3rem;
		margin-bottom: 31px;
	}
	&__meta{
		margin: 66px auto 0;
		max-width: 771px;
	}
	&__content{
		line-height: 1.8;
		img{
			margin-top: 10px;
		}
	}

}